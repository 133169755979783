.App {
  font-family: sans-serif;
}

#logo {
  width: 200px;
  margin-bottom: 10px;
  margin-left: 38px;
  margin-top: 30px;
}

.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
}

@media (max-width: 767px) {
  #logo {
    width: 200px;
    margin-bottom: 10px;
    margin-left: 8px;
    margin-top: 10px;
  }

  .markdown-body {
    padding: 15px;
  }
}
